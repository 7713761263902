// App.js
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./components/Homepage";
import "./App.css";
import BookedPage from "./components/Booking/BookedPage";
import AboutCoach from "./components/About/AboutCoach";
import AboutSession from "./components/About/AboutSession";
import SessionList from "./components/Sessions/SessionList";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Player from "./components/Player/Player";
import NewCard from "./components/Sessions/NewCard";
import Login from "./components/Login/Login";
import PrivateRoute from "./routes/privateRoutes";
import { StateProvider } from "./components/context/TabContext";

const App = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const platform = queryParams.get("platform");
  const token = queryParams.get("token");

  let isAuthenticated = false;  

  useEffect(() => {
    if (platform !== null && platform !== undefined) {
      localStorage.setItem("platform", platform);
      isAuthenticated = true;
    }
    if (token !== undefined && token !== null) {
      localStorage.setItem("authTocken", token);
      isAuthenticated = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (localStorage.getItem("authTocken")){
    isAuthenticated = true;
  }

  return (
    <>
    <StateProvider>
    <Routes>
        <Route path="/login" element={<Login />} />

        {/* Home Route */}
        <Route path={"/"} element={
          <PrivateRoute path={'/'} isAuthenticated={isAuthenticated}>
            <HomePage />
          </PrivateRoute>
        } />


        <Route path="/newCard" element={
        <PrivateRoute path={'/newCard'} isAuthenticated={isAuthenticated}>
        <NewCard />
        </PrivateRoute>} />

        <Route path={"/booking"} element={
          <PrivateRoute path={'/booking'} isAuthenticated={isAuthenticated}>
            <BookedPage />
          </PrivateRoute>
        } />

         <Route path={"/aboutCoach"} element={
          <PrivateRoute path={'/aboutCoach'} isAuthenticated={isAuthenticated}>
            <AboutCoach />
          </PrivateRoute>
        } />

        <Route path={"/aboutSession"} element={
          <PrivateRoute path={'/aboutSession'} isAuthenticated={isAuthenticated}>
            <AboutSession />
          </PrivateRoute>
        } />

        <Route path={"/sessions"} element={
          <PrivateRoute path={'/sessions'} isAuthenticated={isAuthenticated}>
            <SessionList />
          </PrivateRoute>
        } />

        <Route path={"/player"} element={
          <PrivateRoute path={'/player'} isAuthenticated={isAuthenticated}>
            <Player />
          </PrivateRoute>
        } />


        {/* <Route path="/aboutCoach" element={<AboutCoach />} /> */}
        {/* <Route path="/aboutSession" element={<AboutSession />} /> */}
        {/* <Route path="/sessions" element={<SessionList />} /> */}
        {/* <Route path="/player" element={<Player />} /> */}
      </Routes>
    </StateProvider>
     
    </>
  );
};

export default App;
