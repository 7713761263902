import React, { useState } from "react";
import {
  Card,
  Typography,
  Stack,
  Box,
  CardContent,
  CardMedia,
  Button,
  capitalize,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { axiosFetch } from "../../Utils/AxiosFetch";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import ZoomDeepLinkButton from "./ZoomDeepLinkButton";
import ProgramDeepLinkButton from "./ProgramDeepLinkButton";
import { styles } from "./styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const utcFormater = (utc) => {
  let inputDate;
  let formattedDate;

  if (utc) {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString("en-GB");
    inputDate = localTime.split(",");

    const [day, month, year] = inputDate[0].split("/");
    formattedDate = `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
  } else {
    return [];
  }

  return [formattedDate, inputDate];
};

export default function SessionCard({ session, tabValue, setValue, startDate }) {
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackServerity, setSnackServerity] = useState("success");

  let [open, setOpen] = useState(false);
  let [openBuy, setOpenBuy] = useState(false);

  const handleClose = () => setOpen(false);
  const handleCloseBuy = () => {
    setOpenBuy(false);
    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleYes = async () => {
    try {
      // const leadId = JSON.parse(localStorage.getItem("userDetails"))?.id;
      const response = await axiosFetch({
        url: `v3/updateStage`,
        method: "post",
        // requestConfig: {
        //   leadId,
        // },
      });

      if (response.status === 200) {
        setOpenSnackbar(true);
        setSnackMessage(
          "Thank you for your interest! Our FitMom team will reach out to you shortly."
        );

        setOpenBuy(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNo = () => {
    setOpenSnackbar(true);
    setSnackMessage(
      "No worries! Feel free to explore other features, and we're here whenever you're ready."
    );
    setOpenBuy(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "75%",
      md: "50%",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const bull = (level) => {
    return (
      <>
        <Box
          component="span"
          sx={{
            display: "inline-block",
            mx: "3px",
            transform: "translate(5px, 4px)",
            color:
              level.toLowerCase() === "beginner"
                ? "#32991F"
                : level.toLowerCase() === "expert"
                ? "#C81F22"
                : "#FFC859",
            fontSize: "19px",
          }}
        >
          •
        </Box>
        &nbsp; {session.level !== null ? `${session.level}` : ""} &nbsp; •
        &nbsp;{" "}
      </>
    );
  };

  const openNote = () => {
    setOpen(true);
  };
  const joinMeeting = (url) => {
    window.open("https://www.google.com", "_blank");
  };

  const handleBooking = (session) => {
    let { start_time_label, start_date, meeting_id, id, coach_name } = session;

    if (id !== undefined && meeting_id !== undefined) {
      axiosFetch({
        url: "/bookProgramSession",
        method: "post",
        requestConfig: {
          session_id: id,
          meeting_id: meeting_id,
          program_id: session?.program_id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setOpenSnackbar(true);
              if (res.data.message) {
                setSnackMessage(res.data.message);
                setSnackServerity("success");
                setTimeout(() => {
                  setValue(1);
                }, 2000);
                window.AppInterface.setreminder(
                  session.start_time_label,
                  "sample text"
                );
                // navigate("/booking", {
                //   state: {
                //     coach_name: coach_name,
                //     start_time: start_time_label,
                //     start_date: start_date,
                //     when: when,
                //     zoom_link: session.join_url,
                //   },
                // });
              }
            }
          } else if (res.status === 400) {
            // setSnackMessage(res.data.message);
            //setSnackServerity("error");
            if (res.data.ERR_CODE) {
              if (res.data.ERR_CODE === "MUST_BUY_PROGRAM") {
                setOpenBuy(true);
              }
            }
          } else if (res.status === 500) {
            setOpenSnackbar(true);
            setSnackMessage("Internal server error");
            setSnackServerity("error");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleAbout = (type, session) => {
    if (type === 1) {
      navigate("/aboutCoach", { state: { session: session, tabValue: tabValue } });
    } else {
      navigate("/aboutSession", { state: { session: session, tabValue: tabValue } });
    }
  };

  const handleRemainder = (bookingId) => {
    if (bookingId !== null) {
      axiosFetch({
        url: "/setReminderForProgramSession",
        method: "post",
        requestConfig: {
          booking_id: `${bookingId}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setOpenSnackbar(true);
            setSnackMessage(res.data.message);

            setSnackServerity("success");
            window.location.reload();
          } else if (res.status === 400) {
            setOpenSnackbar(true);
            setSnackMessage(res.data.message);
            setSnackServerity("error");
          } else if (res.status === 500) {
            setSnackMessage("Internal server error");
            setSnackServerity("error");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleVideoStreaming = (session) => {
    let recording;
    let { meeting_id, played_seconds } = session;
    axiosFetch({
      url: `/getProgramSessionPlayUrl?meeting_id=${meeting_id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          recording = res.data.body;
          if (!!res.data.body) {
            navigate("/player", {
              state: {
                recording: recording,
                meetingId: meeting_id,
                played_seconds: played_seconds,
              },
            });
          }
        } else if (res.status === 400) {
          setOpenSnackbar(true);
          setSnackMessage(res.data.message);
          setSnackServerity("error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTextColor = (action_label) => {
    // red color
    if (
      action_label?.toLowerCase() === "resume" ||
      action_label?.toLowerCase() === "book now"
    ) {
      return "#C81F22";
    } else if (
      // ash color
      action_label?.toLowerCase() === "remind me"
    ) {
      return "#757575";
    } else if (
      // green color
      action_label?.toLowerCase() === "join now" ||
      action_label?.toLowerCase() === "complete"
    ) {
      return "#32991F";
    } else if (action_label?.toLowerCase() === "play") {
      // blue color
      return "#066BB5";
    } else if (action_label === "Booked") {
      return "#e8590c !important";

      //  color: "red !important",
    }
  };

  const handleBackgroundColor = (action_label) => {
    // red color
    if (
      action_label?.toLowerCase() === "resume" ||
      action_label?.toLowerCase() === "book now"
    ) {
      return "#FAD6D6";
    } else if (
      // ash color
      action_label?.toLowerCase() === "remind me"
    ) {
      return "#DBDBDB";
    } else if (
      // green color
      action_label?.toLowerCase() === "join now" ||
      action_label?.toLowerCase() === "complete"
    ) {
      return "#CCFFC2";
    } else if (action_label?.toLowerCase() === "play") {
      // blue color
      return "#C9E8FF";
    } else if (action_label === "Booked") {
      return "#ffd8a8";
    }
  };

  const handleBook = () => {
    switch (session.action_label) {
      case "Play":
      case "Resume":
        handleVideoStreaming(session);
        break;
      case "Join now":
        openNote(session.join_url, session.zoom_meeting_id);
        break;
      case "Cancelled":
        return;
      default:
        if (session.allow_reminder) {
          handleRemainder(session.booking_id);
        } else {
          handleBooking(session);
        }
        break;
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackServerity}>
          {snackMessage}
        </Alert>
      </Snackbar>

      <Card sx={{ display: "flex", ...styles.card }}>
        <div style={{ borderRadius: "6px", padding: "6px" }}>
          <CardMedia
            component="img"
            sx={styles.image}
            image={session.thumbnail}
            onClick={() => handleAbout(2, session)}
          />
        </div>

        <Box
          sx={{ display: "flex", flexDirection: "column", ...styles.title }}
          className="small-device"
        >
          <CardContent
            sx={{
              flex: "1 0 auto",
              margin: "auto",
              paddingBottom: "8px !important",
              padding: "12px 4px",
            }}
          >
            <Typography
              component="div"
              fontSize="12px"
              textTransform="capitalize"
              onClick={() => handleAbout(2, session)}
              color={"#000"}
              sx={{
                ...styles.session_name_css,
                cursor: "pointer",
                fontWeight: "600",
              }}
            >
              {session.session_name !== null ? session.session_name : "-"}
            </Typography>

            <Typography
              fontSize="10px"
              component="div"
              sx={{ color: "#757575" }}
            >
              {session.coach_name != null ? `With ${session.coach_name}` : " "}
            </Typography>

            <Typography
              fontSize="8px"
              component="div"
              fontWeight={600}
              font="Gordita"
              sx={{ textTransform: "capitalize", marginLeft: "-7px" }}
            >
              {bull(session.level)}

              {session.duration !== null && session.duration_unit !== null
                ? `${session.duration} ${session.duration_unit}`
                : ""}

              <span style={{ marginLeft: "10px" }}>
                {utcFormater(session?.meetings?.start_time)[0]}
              </span>
            </Typography>
            
          </CardContent>
        </Box>
        <Box
          className={"session-book"}
          sx={{
            ...styles.time,
          }}
        >
          <Typography
            component="div"
            variant="h6"
            fontSize="13px"
            color={"#000"}
            className="time-mobile"
          >
            {session.start_time_label !== null ? session.start_time_label : ""}
          </Typography>
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              borderRadius: "20px",
              fontSize: "10px",
              padding: "0px !important",
              background: () => handleBackgroundColor(session.action_label),
              color: () => handleTextColor(session.action_label),
              borderColor: () => handleBackgroundColor(session.action_label),
              textTransform: "capitalize",
            }}
            onClick={handleBook}
            disabled={
              (!session.allow_booking &&
                !session.allow_reminder &&
                session.action_label !== "Play" &&
                session.action_label !== "Resume" &&
                session.action_label !== "Join now") ||
              session.action_label === "Booked"
            }
          >
            <Typography
              sx={{
                borderRadius: "20px",
                fontSize: "10px",
                padding: "2px !important",
              }}
            >
              {session.action_label === "Complete"
                ? "Completed"
                : session.action_label}
            </Typography>
          </Button>
        </Box>
      </Card>

      {/* Ṃodal for join */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ margin: "auto", textAlign: "center" }}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
              >
                Note
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textTransform: "capitalize" }}
              >
                Switch on your camera to receive personalized tips & real-time
                guidance.
              </Typography>
            </div>
            <br />
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                color="error"
                sx={{ width: "136px", textTransform: "capitalize" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {/* <Button
                variant="outlined"
                color="primary"
                sx={{ width: "136px", textTransform: "capitalize" }}
                onClick={() =>
                  joinMeeting(session.join_url, session.zoom_meeting_id)
                }
              >
                Join Session
              </Button> */}
              <ZoomDeepLinkButton
                zoomMeetingId={session.zoom_meeting_id}
                zoomMeetingPassword={session.zoom_meeting_password || ""}
                meetingId={session.meeting_id}
                sessionId={session.id}
                session={session}
                setOpenSnackbar={setOpenSnackbar}
                setSnackMessage={setSnackMessage}
                setSnackServerity={setSnackServerity}
              />
            </Stack>
          </Box>
        </Fade>
      </Modal>

      {/* Ṃodal for buy program */}
      <Modal
        aria-labelledby="buy-program-modal-title"
        aria-describedby="buy-program-modal-description"
        open={openBuy}
        onClose={() => setOpenBuy(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openBuy}>
          <Box sx={style}>
            <div style={{ margin: "auto", textAlign: "center" }}>
              <Typography id="buy-program-modal-title" sx={{ mt: 2 }}>
                This feature isn't available in your current plan.
              </Typography>
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                mt: "20px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#000",
                  },
                  borderColor: "#000",
                  color: "#000",
                  px: "25px",
                }}
                onClick={handleNo}
              >
                Maybe Later
              </Button>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
                onClick={handleYes}
              >
                Upgrade Now
              </Button>
            </Box>

            {/* [Yes, Upgrade Now] [No, Maybe Later] */}
            <br />
            {/* <Stack
              direction="row"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <ProgramDeepLinkButton close={handleCloseBuy} />
            </Stack> */}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
